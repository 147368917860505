$(function () {
    initNav();
    initSlideshow();
    initProductCarousel();
    initAssortimentCarousel();
    initMaps();
    initProductDetailSlick();
    initFaq();
});

$(window).resize(function () {
    clearTimeout($.data(this, 'resizeTimer'));
    $.data(this, 'resizeTimer', setTimeout(function () {
        // Actions on resize
    }, 200));
});

function initNav() {
    $('header .nav-toggler').click(function (e) {
        $('#nav_wrapper nav').toggleClass('toggle');
    });
}

function initSlideshow() {
    $('#slideshow').slick({
        autoplay: true,
        arrows: false,
        dots: true
    });
}

function initProductCarousel() {
    $('#product_carousel').slick({
        arrows: false,
        dots: true,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
}

function initAssortimentCarousel() {
    $('#assortiment_carousel').slick({
        arrows: false,
        dots: true,
        slidesToShow: 5,
        slidesToScroll: 5,
        responsive: [
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });
}

function initMaps() {
    if ($('#maps').length > 0) {
        var latlng = new google.maps.LatLng($('#maps').data('lat'), $('#maps').data('lng'));

        map = new google.maps.Map(document.getElementById("maps"), {
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            center: latlng,
            scrollwheel: false
        });

        var marker = new google.maps.Marker({
            position: latlng,
            map: map,
            icon: '/img/icon-map.png'
        });
    }
}

function initProductDetailSlick() {
    $('.slick').slick({
        arrows: false,
        dots: false,
        asNavFor: '.slick-nav'
    });

    $('.slick-nav').slick({
        arrows: false,
        dots: false,
        vertical: true,
        verticalSwiping: true,
        slidesToShow: 3,
        asNavFor: '.slick',
        focusOnSelect: true
    });
}

function initFaq() {
    if ($('#faq').length > 0) {
        $('.question').on('click', function () {
            $(this).siblings().removeClass('show');
            $(this).toggleClass('show');
        });
    }
}